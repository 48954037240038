<template>
  <v-container fluid tag="section">
    <v-container fluid tag="section" class="py-0">
      <v-row class="secondary px-4 br-8 pt-0 pb-2">
        <!-- Product List Section -->
        <v-col cols="12" md="8">
          <!-- Filter and Search Section -->
          <v-row class="mb-4" align="center">
            <v-combobox
              v-model="category_filter"
              :items="categories_name_list"
              item-text="name"
              item-value="value"
              label="Select Category"
              hide-details
              clearable
            ></v-combobox>

            <v-spacer></v-spacer>

            <v-text-field
              v-model="searchQuery"
              append-icon="mdi-magnify"
              label="Search products"
              single-line
              hide-details
              dense
            ></v-text-field>
          </v-row>

          <!-- Product Grid with Pagination -->
          <v-row>
            <v-col
              v-for="product in paginatedProducts"
              :key="product.id"
              cols="6"
              md="3"
            >
              <v-card
                class="product-card"
                height="200px"
                @click="addToCart(product)"
              >
                <v-img :src="product.image" height="120px"></v-img>
                <v-card-title class="product-name truncate-title">{{
                  product.name
                }}</v-card-title>
                <v-card-subtitle class="product-price">{{
                  product.price | currency
                }}</v-card-subtitle>
                <v-badge
                  :content="product.quantity > 0 ? product.quantity : '0'"
                  color="primary"
                  overlap
                ></v-badge>
              </v-card>
            </v-col>
          </v-row>

          <!-- Pagination -->
          <v-row justify="center" class="mt-4">
            <v-pagination
              v-model="currentPage"
              :length="pageCount"
              @input="onPageChange"
            ></v-pagination>
          </v-row>
        </v-col>

        <!-- Cart Sidebar -->
        <v-col cols="12" md="4">
          <v-card class="cart-sidebar">
            <v-list dense>
              <v-list-item
                v-for="item in cart"
                :key="item.id"
                class="d-flex justify-space-between"
              >
                <div class="d-flex flex-column">
                  <strong class="truncate">{{ item.name }}</strong>
                  <div class="d-flex align-center">
                    <v-btn icon small @click="decreaseQuantity(item)">-</v-btn>
                    <span class="mx-2">{{ item.cartQuantity }}</span>
                    <v-btn icon small @click="increaseQuantity(item)">+</v-btn>
                  </div>
                </div>
                <span>{{ (item.cartQuantity * item.price) | currency }}</span>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <!-- // modified by vikas on 11th of december -->
            <v-list-item class="d-flex justify-space-between">
              <strong>Subtotal (Excl. VAT)</strong>
              <span>{{ subtotal | currency }}</span>
            </v-list-item>

            <v-list-item class="d-flex justify-space-between">
              <strong>Total VAT</strong>
              <span>{{ totalVat | currency }}</span>
            </v-list-item>

            <v-list-item class="d-flex justify-space-between">
              <strong>Total (Incl. VAT)</strong>
              <span>{{ total | currency }}</span>
            </v-list-item>
            <!-- // modified by vikas on 11th of december -->

            <v-btn
              color="primary"
              block
              @click="checkout"
              :disabled="loading || total <= 0"
            >
              Start Checkout: {{ total | currency }}
              <v-progress-circular
                v-if="loading"
                :size="20"
                indeterminate
                color="white"
              />
            </v-btn>
            <!-- Button to retry payment capture -->
            <v-btn
              v-if="show_retry"
              block
              @click="retryProcessPaymentIntent"
              :disabled="loading"
              color="warning"
            >
              Retry
            </v-btn>
            <!-- Button to Clear Payment cart -->
            <v-btn
              v-if="show_retry"
              block
              @click="clearCheckout"
              :disabled="loading"
              color="warning"
            >
              Clear Checkout
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="show_snackbar" top right color="red" timeout="6000"
      ><span class="black--text font-weight-bold">{{
        this.snackbarcustommessage
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="show_snackbar_green"
      top
      right
      color="green"
      timeout="2000"
      ><span class="black--text font-weight-bold">{{
        this.snackbarcustommessage
      }}</span></v-snackbar
    >
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { db, firestore } from "@/db"; //Added by vikas on 10th of december
import {
  check_terminal_status,
  // initiate_payment,
  create_terminal_payment_intent,
  process_terminal_payment_intent,
  //capture_terminal_payment,
  check_terminal_payment_intent_status,
} from "@/requests";

export default {
  name: "products",
  data() {
    return {
      category_filter: null,
      searchQuery: "",
      currentPage: 1,
      products_up: this.products,
      cart: [],
      itemsPerPage: 16,
      stripeTerminal: null,
      loading: false,
      show_snackbar: false,
      snackbarcustommessage: "",
      payment_intent_id: null,
      show_snackbar_green: false,
      show_retry: false,
      loading_retry: false,
      selected_brand: null, //Added by vikas on 10th of december
      //Added by vikas on 10th of december
      order_details: {
        service: "collection",
        created_at: this.generateTimestamp(),
        tip: 0,
        custom_fee_name: null,
        payment_intent_id: "pi_3OuuHbB89aKqMUiz0pxwWb9l", //give payment intent id
        delivery_fee: 0,
        active_voucher: null,
        updated_at: this.generateTimestamp(),
        receipt_ref: null,
        customer: null,
        brand_id: this._selected_brand, // "bs3", // Give brand_id
        confirmations: [],
        fulfilment_date: null,
        create_date: new Date().toISOString().split("T")[0], // Give date time
        custom_fee: null,
        total: 0, // Total Amount
        discount: 0,
        status: "processing",
        order_no: 265, // Generate Order Number
        products: [
          {
            options: [], // options
            total_price: 20, // price
            sku: null, // sku
            product_id: "tbtYrH8H6Y6kZAAYa9qn", // id
            total_vat: 0, // vat
            quantity: 2, // quantity
            name: "£10 GIFT VOUCHER", // name
            price: 10, // price
            vat: 0, // vat
            category: "GIFT VOUCHERS", // categories.category
          },
        ],
      },
      //Added by vikas on 10th of december till here
    };
  },
  computed: {
    ...mapState("AdminStore", [
      "shop",
      "shop_id",
      "product_categories",
      "shop_products_categories",
    ]),
    ...mapState("ShopStore", ["shop"]), //Added by vikas on 10th of december
    ...mapGetters("AdminStore", ["products", "all_product_categories"]),

    categories_name_list: function () {
      if (!this.shop_products_categories || !this.all_product_categories)
        return [];
      return Object.keys(this.shop_products_categories)
        .map((id) => this.all_product_categories[id])
        .sort();
    },
    //Added by vikas on 10th of december
    _selected_brand: {
      get() {
        return this.selected_brand || this.shop.brands[0].id;
      },
      set(brand) {
        this.selected_brand = brand;
      },
    },
    //Added by vikas on 10th of december

    filteredProducts() {
      console.log("shopid from store : ", this.shop_id);
      let products = this.products.filter((product) => product.enabled == true);

      if (this.category_filter && this.category_filter !== "all") {
        products = products.filter(
          (product) => product.category === this.category_filter
        );
      }

      if (this.searchQuery) {
        products = products.filter((product) =>
          product.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      return products;
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      return this.filteredProducts.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.filteredProducts.length / this.itemsPerPage);
    },
    // modified by vikas on 11th of december
    // Calculate subtotal (base price × quantity)
    subtotal() {
      return this.cart.reduce((total, item) => {
        return total + item.basePrice * item.cartQuantity;
      }, 0);
    },

    // Calculate total VAT amount for all items in the cart
    totalVat() {
      return this.cart.reduce((totalVat, item) => {
        return totalVat + item.vatAmount * item.cartQuantity;
      }, 0);
    },

    // Calculate total (subtotal + total VAT)
    total() {
      return this.subtotal + this.totalVat;
    },
    // modified by vikas on 11th of december
  },
  watch: {
    // Reset currentPage when category filter or search query changes
    category_filter() {
      this.currentPage = 1;
    },
    searchQuery() {
      this.currentPage = 1;
    },
  },
  methods: {
    clearCheckout() {
      (this.cart = []),
        (this.loading = false),
        (this.show_snackbar = false),
        (this.snackbarcustommessage = ""),
        (this.payment_intent_id = null),
        (this.show_snackbar_green = false),
        (this.show_retry = false),
        (this.loading_retry = false);
    },
    //Added by vikas on 10th of december
    addOrder(orderDetails) {
      // Step 1: Generate order number
      this.getOrderNumber((order_number) => {
        // Step 2: Add the order number to the order details
        orderDetails.order_no = order_number;
        //orderDetails.created_at = new Date().toISOString();
        //orderDetails.updated_at = new Date().toISOString();

        // Reference to the orders collection in Firestore
        const ordersRef = db
          .collection("shops")
          .doc(this.shop_id)
          .collection("orders");

        // Step 3: Add the order to Firestore
        ordersRef
          .add(orderDetails)
          .then((docRef) => {
            console.log("Order added with ID:", docRef.id);
          })
          .catch((error) => {
            console.error("Error adding order:", error);
          });

        // Optionally, increment the next_order_no in Firestore
        // const stats_ref = db
        //   .collection("shops")
        //   .doc(this.shop_id)
        //   .collection("orders")
        //   .doc("--stats--");

        // db.runTransaction((trans) => {
        //   trans.update(stats_ref, {
        //     next_order_no: firestore.FieldValue.increment(1),
        //   });
        // });
      });
    },

    mapCartToOrderDetails() {
      // this.getOrderNumber((order_number) => {
      //   this.order_details.order_no = order_number;
      // });
      this.order_details.customer = {
        name: this.shop_id,
        phone_no: this.shop.brands[0].id,
        email: this.shop.brands[0].id,
        notes: "",
      };
      this.order_details.total = this.total;

      this.order_details.payment_intent_id = this.payment_intent_id;

      this.order_details.brand_id = this.shop.brands[0].id;
      this.order_details.products = this.cart.map((item) => ({
        options: item.options || [],
        total_price: item.price * item.cartQuantity,
        sku: item.sku || null,
        product_id: item.id,
        total_vat: item.vat || 0,
        quantity: item.cartQuantity,
        name: item.name,
        price: item.price,
        vat: item.vat || 0,
        category: item.category,
      }));
    },
    generateTimestamp() {
      const now = new Date();
      const seconds = Math.floor(now.getTime() / 1000); // Current time in seconds
      const nanoseconds = (now.getTime() % 1000) * 1000000; // Remainder converted to nanoseconds

      return {
        seconds: seconds,
        nanoseconds: nanoseconds,
      };
    },
    getOrderNumber(callback) {
      const stats_ref = db
        .collection("shops")
        .doc(this.shop_id)
        .collection("orders")
        .doc("--stats--");

      return db.runTransaction(async (trans) => {
        const stats = await trans.get(stats_ref);
        const order_number = stats.data().next_order_no;

        trans.update(stats_ref, {
          next_order_no: firestore.FieldValue.increment(1),
        });

        callback(order_number);
      });
    },
    //Added by vikas on 10th of december
    // modified by vikas on 11th of december
    addToCart(product) {
      if (product.quantity > 0) {
        const cartItem = this.cart.find((item) => item.id === product.id);

        // Calculate VAT and base price
        const vatRate = product.vat / 100;
        const basePrice = product.price / (1 + vatRate); // Price excluding VAT
        const vatAmount = product.price - basePrice; // Extracted VAT amount

        if (cartItem) {
          cartItem.cartQuantity += 1;
        } else {
          this.cart.push({
            id: product.id,
            ...product,
            cartQuantity: 1,
            basePrice: basePrice,
            vatAmount: vatAmount,
          });
        }

        product.quantity -= 1;
      }
      console.log("cart content:", this.cart);
    },
    // modified by vikas on 11th of december
    increaseQuantity(item) {
      const product = this.products.find((prod) => prod.id === item.id);
      if (product && product.quantity > 0) {
        item.cartQuantity += 1;
        product.quantity -= 1;
      }
    },
    decreaseQuantity(item) {
      const product = this.products.find((prod) => prod.id === item.id);
      if (item.cartQuantity > 1) {
        item.cartQuantity -= 1;
        if (product) {
          product.quantity += 1;
        }
      } else {
        this.cart = this.cart.filter((cartItem) => cartItem.id !== item.id);
        if (product) {
          product.quantity += 1;
        }
      }
    },
    onPageChange(page) {
      this.currentPage = page;
    },

    async checkout() {
      try {
        this.loading = true;
        this.show_retry = false;

        // Step 1: Check terminal status
        let response = await check_terminal_status();
        console.log(response);
        if (response.data.isOnline) {
          console.log("Terminal is online. Initiating payment...");

          // Step 2: Create terminal payment intent
          let response_payment = await create_terminal_payment_intent({
            amount: this.total * 100, // Convert to smallest currency unit
            shop_id: this.shop_id, // Static shop_id defined in data()
          });
          console.log("Payment Intent Creation Response:", response_payment);

          if (response_payment.data.success) {
            this.payment_intent_id = response_payment.data.payment_intent_id;
            console.log("Process Payment Intent process started");

            // Step 3: Process terminal payment intent
            let process_response = await process_terminal_payment_intent({
              payment_intent_id: this.payment_intent_id,
              shop_id: this.shop_id,
            });
            console.log("Process Payment Intent Response:", process_response);
            this.mapCartToOrderDetails(); //Added by vikas on 10th of december
            this.addOrder(this.order_details); //Added by vikas on 10th of december

            if (
              process_response.data.success &&
              process_response.data.status == "requires_payment_method"
            ) {
              // Step 4: Ceck terminal payment status
              let response = await check_terminal_payment_intent_status({
                payment_intent_id: this.payment_intent_id,
                shop_id: this.shop_id,
              });
              console.log("Check Payment Status Response:", response);

              if (response.data.success) {
                this.snackbarcustommessage =
                  "Payment status: " + response.data.status;
                this.show_snackbar_green = true;
              } else {
                this.show_retry = true;
                this.snackbarcustommessage =
                  "Failed to check payment status: " +
                  response.data.message +
                  " click on RETRY OR CLEAR CHECKOUT to proceed further.";
                this.show_snackbar = true;
              }
            } else {
              this.snackbarcustommessage =
                "Payment processing: " + process_response.data.message;
              this.show_snackbar_green = true;
            }
          } else {
            this.snackbarcustommessage =
              "Payment intent creation failed: " +
              response_payment.data.message;
            this.show_snackbar = true;
          }
        } else {
          this.snackbarcustommessage =
            "Terminal is offline. Cannot initiate payment.";
          this.show_snackbar = true;
        }
      } catch (error) {
        console.error("Checkout failed:", error);
        this.snackbarcustommessage =
          "An unexpected error occurred during checkout.";
        this.show_snackbar = true;
      } finally {
        this.loading = false;
      }
    },
    async checkPaymentStatus() {
      try {
        this.loading = true;
        let response = await check_terminal_payment_intent_status({
          payment_intent_id: this.payment_intent_id,
          shop_id: this.shop_id,
        });
        console.log("Check Payment Status Response:", response);

        if (response.data.success) {
          this.snackbarcustommessage =
            "Payment status: " + response.data.status;
        } else {
          this.snackbarcustommessage =
            "Failed to check payment status: " + response.data.message;
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
        this.snackbarcustommessage =
          "An error occurred while checking payment status.";
      } finally {
        this.show_snackbar = true;
        this.loading = false;
      }
    },
    async retryProcessPaymentIntent() {
      try {
        this.loading = true;

        // Call process_terminal_payment_intent with required parameters
        let response = await process_terminal_payment_intent({
          payment_intent_id: this.payment_intent_id,
          shop_id: this.shop_id,
        });
        console.log("Retry Process Payment Intent Response:", response);

        // Check response and set snackbar message
        if (
          response.data.success &&
          response.data.status != "requires_payment_method"
        ) {
          this.snackbarcustommessage = "Payment Intent successfully processed!";
        } else {
          this.snackbarcustommessage =
            "Retry process failed: " + response.data.status;
        }
      } catch (error) {
        console.error("Error retrying payment intent processing:", error);
        this.snackbarcustommessage =
          "An error occurred while retrying payment intent processing.";
      } finally {
        this.show_snackbar = true;
        this.loading = false;
      }
    },
  },
  created() {},
  filters: {
    currency(value) {
      return `£ ${value.toFixed(2)}`;
    },
  },
};
</script>

<style scoped>
.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-name {
  font-weight: bold;
  font-size: 0.7rem;
}
.product-price {
  color: #666;
  padding-top: 10px;
  font-weight: bold;
  font-size: 0.9rem;
}
.cart-sidebar {
  padding: 16px;
  max-width: 300px;
}
.truncate-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}
.truncate {
  display: inline-block; /* Ensures the element is treated as a block for truncation */
  max-width: 120px; /* Adjust width to fit approximately 13 characters */
  white-space: nowrap; /* Prevents text from wrapping to a new line */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds "..." to indicate truncated text */
}
</style>
